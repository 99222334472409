//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";
import IndexSale from "./components/IndexSale";
import Vip from "./components/Vip";
import Goods from "./components/Goods";
export default {
  name: "Index",
  components: { IndexSale, Vip, Goods },
  data() {
    return {
      monthOrCustom: "month",
      timePop: false,
      timeType: "start",
      apis: {},
      dateTxt: this.$moment().format("yyyy-MM"),

      currentDate: new Date(this.$moment().format("yyyy-MM-01")),
      currentMonth: new Date(this.$moment().format("yyyy-MM")),
      tempStartDate: this.$moment().format("yyyy-MM-01"),
      tempEndDate: this.$moment().format("yyyy-MM-DD"),
      tempMonth: this.$moment().format("yyyy-MM"),
      maxDate: null,
      minDate: new Date(2004, 1, 1),
seasonOption: [
        { text: "春夏", value: "X" },
        { text: "秋冬", value: "Y" },
      ],
      seasonVal: "X",
      stores: [],
      storeOption: [],
      storeBrand: "",
      currentStore: "",
      startDate: "",
      endDate: "",

      tab: "sale",
      kdgrp: LocalStorage.getItem("kdgrp"),
      brandCode: "ZBXN",
    };
  },
  mounted() {
    window.document.title = "门店业绩";
  },
  activated() {
    window.document.title = "门店业绩";
  },
  created() {
    window.document.title = "门店业绩";
    const that = this;
    // LocalStorage.set('userOwnerShops', 'E001Z003');
    let temp = LocalStorage.getItem("storeArray");
    if (temp && temp != "" && temp != "null") {
      this.storeBrand = temp[0].storeId + "," + temp[0].brand;
      this.currentStore = temp[0].storeId;
      this.brandCode = temp[0].brand;
      for (let i = 0; i < temp.length; i++) {
        this.storeOption.push({
          text: temp[i].storeId + " " + temp[i].title,
          value: temp[i].storeId + "," + temp[i].brand,
        });
      }
    } else {
      that.storeOption = [];
    }

    let startDate = this.$moment().format("yyyyMM") + "01";
    let endDate = this.$moment().format("yyyyMMDD");
    this.startDate = startDate;
    this.endDate = endDate;
    this.maxDate = new Date();
  },
  methods: {
    storeChange() {
      let res = this.storeBrand.split(",");
      this.currentStore = res[0];
      this.brandCode = res[1];
      if (this.$refs.indexSale) {
        this.$refs.indexSale.updateParams(
          this.currentStore,
          this.startDate,
          this.endDate,
          this.brandCode,
          this.seasonVal
        );
        this.$refs.indexSale.dispatchApi();
      }
      if (this.$refs.indexGoods) {
        this.$refs.indexGoods.updateParams(
          this.currentStore,
          this.startDate,
          this.endDate,
          this.brandCode,
          this.seasonVal
        );
        this.$refs.indexGoods.dispatchApi();
      }

      if (this.$refs.vip) {
        this.$refs.vip.updateParams(this.currentStore, this.brandCode);
        this.$refs.vip.updateData();
      }
    },

    reloadData() {
      if (this.monthOrCustom == "custom") {
        let start = new Date(this.tempStartDate).getTime(),
          end = new Date(this.tempEndDate).getTime();
        if (start > end) {
          //需要交换
          this.startDate = this.$moment(this.tempEndDate).format("yyyyMMDD");
          this.endDate = this.$moment(this.tempStartDate).format("yyyyMMDD");
          this.dateTxt = this.tempEndDate + " 至 " + this.tempStartDate;
        } else {
          this.startDate = this.$moment(this.tempStartDate).format("yyyyMMDD");
          this.endDate = this.$moment(this.tempEndDate).format("yyyyMMDD");
          this.dateTxt = this.tempStartDate + " 至 " + this.tempEndDate;
        }
      } else {
        this.startDate = this.$moment(this.tempMonth).format("yyyyMM01");
        this.endDate = this.$moment(this.tempMonth)
          .endOf("month")
          .format("yyyyMMDD");
        // let month = this.tempMonth.split("-")[1];
        // if (month == new Date().getMonth() + 1) {
        //   this.endDate = this.$moment().format("yyyyMMDD");
        // } else {
        //   let end = this.$moment(this.tempMonth).endOf("month");
        //   this.endDate = this.$moment(end).format("yyyyMMDD");
        // }
        this.dateTxt = this.tempMonth;
      }

            if (this.$refs.indexSale) {
        this.$refs.indexSale.updateParams(
          this.currentStore,
          this.startDate,
          this.endDate,
          this.brandCode,
          this.seasonVal
    
        );
        this.$refs.indexSale.dispatchApi();
      }
      if (this.$refs.indexGoods) {
        this.$refs.indexGoods.updateParams(
          this.currentStore,
          this.startDate,
          this.endDate,
          this.brandCode,
          this.seasonVal
        );
        this.$refs.indexGoods.dispatchApi();
      }
      this.timePop = false;
    },
    timeChange() {
      if (this.monthOrCustom == "custom") {
        if (this.timeType == "start") {
          this.tempStartDate = this.$moment(this.currentDate).format(
            "yyyy-MM-DD"
          );
        } else if (this.timeType == "end") {
          this.tempEndDate = this.$moment(this.currentDate).format(
            "yyyy-MM-DD"
          );
        }
      } else {
        if (this.timeType == "start") {
          this.tempMonth = this.$moment(this.currentMonth).format("yyyy-MM");
        }
      }
    },
    
    seasonChange() {
       if (this.$refs.indexGoods) {
         console.log('this.seasonVal',this.seasonVal)
        this.$refs.indexGoods.updateParams(
          this.currentStore,
          this.startDate,
          this.endDate,
          this.brandCode,
          this.seasonVal
        );
        this.$refs.indexGoods.dispatchApi();
      }
    }
  },
};
